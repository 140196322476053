import ReactDOM from "react-dom/client";

require('typeface-montserrat')
require('typeface-karla')

require('./src/styles/prism-custom-dark.css')
// require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

// https://github.com/gatsbyjs/gatsby/discussions/36232#discussioncomment-6145675
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}